import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { sha256, sha224 } from 'js-sha256'; 
import { Key } from '../key';
import { Device } from '../device';
import { Global } from "../global";

//companies
import Company from '../models/Company';
import CompanyUnit from '../models/CompanyUnit';
import {CompanyService} from '../services/company.service';

import { Observable } from 'rxjs';
import { t } from '@angular/core/src/render3';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { debug } from 'util';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public logins: Login[] = [];
  public loginsTab: Login[] = [];
  public keys: Key[] = [];
  public devices: Device[] = [];

  public toggleDevicesMode = false;
  public toggleKeysMode = false;
  public toggleUsersMode = false;

  public passUpdated: false;

  public loginsFilter = '';
  public devicesFilter = '';
  public keysFilter = '';
  public usersFilter = '';

  public itemsPerPageUsers = 15;
  public itemsPerPageDevices = 15; 
  public itemsPerPageKeys = 15;
  public itemsPerPageUsersTab = 15;
  public p4: number = 1;
  public p3: number = 1;
  public p2: number = 1;
  public p1: number = 1;

  public selectedLogins: Login[] = [];
  public activatedLogin: Login = new Login();

  public initialAccess: LoginAccess;

  public userRole: number = Number(localStorage.getItem('role'));
  public companyShowTable: boolean;
  public editUserSetting = false;

  public activeUserSettings: LoginUserAccess;
  // company
  public companies: Company[]
  public companyUnits: CompanyUnit[]

  public isUnitDisabled = true;

  //acceess to btns
  public isAddUserDisabled: boolean = true;
  public isEditUserDisabled: boolean = true;
  public isEditAccessUserDisabled: boolean = true;
  public isUpdateAccessesDisabled: boolean = true;

  //aacceess to select in change acceess
  public isSameRole: boolean = false;
  public isAdminRole: boolean = false;

  // devices and card group
  public devGroup: DevGroup[]
  public keyGroup: KeyGroup[]
  public activeDevGroup: number;
  public activeKeyGroup: number;

  public editUsersAccess: boolean = false;


  ngOnInit() { 

    if(this.global.login_acc > 2 || this.userRole == 0 || this.userRole == 1){
      this.isAddUserDisabled = false
    } 

    if(+localStorage.getItem('login_acc') > 1 || this.userRole == 0 || this.userRole == 1){      
      this.isEditUserDisabled = false
    }

    if(this.global.login_acc > 1 || this.userRole == 0 || this.userRole == 1){
      this.isUpdateAccessesDisabled = false
    }

    if(+localStorage.getItem('login_acc') > 1 || this.userRole == 0 || this.userRole == 1){      
      this.editUsersAccess = true
    }
  }

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private companyService: CompanyService,public global: Global) {

    Number(localStorage.getItem('company_id')) ? this.companyShowTable = false : this.companyShowTable = true

    this.global.userId = +localStorage.getItem('user_id')
    this.global.userRole = +localStorage.getItem('role')
    this.global.login_acc = +localStorage.getItem('login_acc')


    if(localStorage.getItem('company_id')){
      const parameters = {
        login_id: localStorage.getItem('user_id')
      }

      this.http.get<LoginLoginAccess[]>(this.baseUrl + 'api/Login/getLoginLogins', { params: parameters }).subscribe(r => {
        const loginsAr = []
        r.map(x=>{
          loginsAr.push(x.to_login_id)
        })
        const params = {
          logins: loginsAr,
          company_id: localStorage.getItem('company_id'),
        }
        
        this.getLoginsCompany(params).subscribe(result => {

          this.loginsTab = result;
          this.logins = JSON.parse(JSON.stringify(result));
          this.logins.map(function (item) {
            item.roleName = item.role == 0 ? 'Super' : item.role == 1 ? 'Admin' : 'User'  
            item.statusName = item.status == 0 ? 'Active' : item.status == 1 ? 'Block' : 'Del'  
            item.isSelected = false;
          });
        });
      })
    }else{
      this.getLogins()
    }


    http.get<Device[]>(this.baseUrl + 'api/Devices/GetDevices').subscribe(result => {
      this.devices = result;
    }, error => {
      console.log(error);
    });
    http.get<Key[]>(this.baseUrl + 'api/Keys/GetKeys').subscribe(result => {
      this.keys = result;
    }, error => {
      console.log(error);
    });
    
    if(localStorage.getItem('role') == '0'){
      this.companyService.getCompanies().subscribe(r => this.companies = r)
    }else{
      const params = {
        company_id: Number(localStorage.getItem('company_id'))
      }
      this.companyService.getCompanyUnits(params).subscribe(r=>{
        this.companyUnits = r
        this.isUnitDisabled = false
      })
    }
    //Devices and Card Groups
    this.http.get<DevGroup[]>(this.baseUrl + 'api/Access/GetDevicesGroup').subscribe(r => this.devGroup = r);
    this.http.get<KeyGroup[]>(this.baseUrl + 'api/Access/GetKeysGroup').subscribe(r => this.keyGroup = r);

  }


 
  getLogins() {

    this.http.get<Login[]>(this.baseUrl + 'api/Login/GetLogins').subscribe(result => {
      this.loginsTab = result;
      this.logins = JSON.parse(JSON.stringify(result));
      this.logins.map(function (item) {
        item.roleName = item.role == 0 ? 'Super' : item.role == 1 ? 'Admin' : 'User'  
        item.statusName = item.status == 0 ? 'Active' : item.status == 1 ? 'Block' : 'Del'  
        item.isSelected = false;
      });
    });
  }

  getLoginsCompany(parameters):Observable<Login[]> {
    return this.http.get<Login[]>(this.baseUrl + 'api/Login/GetLoginsCompany', { params: parameters })
  }

  setActiveCompany(e){
    const params = {
      company_id: e
    }
    this.companyService.getCompanyUnits(params).subscribe(r=>this.companyUnits = r)
  }

  setActiveRole(e){    
    e == 1 ? this.isUnitDisabled = true : this.isUnitDisabled = false;this.activatedLogin.group_id = null
  }
  
  
  toggle(S, x) {
    // tslint:disable-next-line:no-bitwise
    S[x] = 1 - (S[x] | 0);
  }

  toggleAll(type) {
    const self = this;

    switch (type) {
      case 'key': this.toggleKeysMode = !this.toggleKeysMode;
        this.keys.map(function (item) {item.isSelected = self.toggleKeysMode;
        });
        break;
      case 'device': this.toggleDevicesMode = !this.toggleDevicesMode;
        this.devices.map(function (item) { item.isSelected = self.toggleDevicesMode; });
        break;
      case 'users': this.toggleDevicesMode = !this.toggleDevicesMode;
        this.logins.map(function (item) { item.isSelected = self.toggleDevicesMode; });
        break;
    }
  }

  sortListInTable(list) {
    for (let i = 0; i < list.length; ++i) {
      list[i].sort(function (x, y) {
        return Number(y.isSelected) - Number(x.isSelected);
      });
    }
  }


  toggleSelected(item: Login, refresh: boolean) {
    console.log(this.global.userRole);

    if(item.groupName){
      this.isUnitDisabled = false
      const params = {
        company_id: item.company_id
      }
      this.companyService.getCompanyUnits(params).subscribe(r=>{
        this.companyUnits = r
        this.isUnitDisabled = false
      })
    }else{
      this.isUnitDisabled = true
    }

      if (this.activatedLogin !== item || refresh) {

        this.activatedLogin = item;

        this.http.post<LoginAccess>(this.baseUrl + 'api/Access/GetLoginAccesses', this.activatedLogin.id).subscribe(result => {

        this.keys.map(function (item) {
          item.isSelected = result.keyAccess.includes(item.id) ? true : false;
        });

        this.devices.map(function (item) {
          item.isSelected = result.deviceAccess.includes(item.id) ? true : false;
        });

        this.loginsTab.map(function (item) {
          item.isSelected = result.loginAccess.includes(item.id) ? true : false;
        });

        this.initialAccess = result;

        // console.log(this.initialAccess);
        this.sortListInTable([this.keys, this.devices, this.loginsTab])

          /*
          this.logins = this.logins.sort(function (x, y) {
            return x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1;
          });
          */
      });
    }
  }

  updateAccesses() {
    const self = this;
    const newDeviceAccesses = [];
    this.devices.map(function (item) {
      if (self.initialAccess.deviceAccess.includes(item.id) && item.isSelected === false) {
        newDeviceAccesses.push(new LoginDeviceAccess(self.initialAccess.loginID, item.id, 0));
      }
      if (!self.initialAccess.deviceAccess.includes(item.id) && item.isSelected === true) {
        newDeviceAccesses.push(new LoginDeviceAccess(self.initialAccess.loginID, item.id, 1));
      }
    });
    console.log(newDeviceAccesses);
    
    this.http.post<boolean>(this.baseUrl + 'api/Access/UpdateDeviceAccess', newDeviceAccesses).subscribe(result => {
      const newKeyAccesses = [];
      this.keys.map(function (item) {
        if (self.initialAccess.keyAccess.includes(item.id) && item.isSelected === false) {
          newKeyAccesses.push(new LoginKeyAccess(self.initialAccess.loginID, item.id, 0));
        }
        if (!self.initialAccess.keyAccess.includes(item.id) && item.isSelected === true) {
          newKeyAccesses.push(new LoginKeyAccess(self.initialAccess.loginID, item.id, 1));
        }
      });
      this.http.post<boolean>(this.baseUrl + 'api/Access/UpdateKeyAccess', newKeyAccesses).subscribe(result => {
        document.getElementById('openAccessUpdateModal').click();
        self.toggleSelected(self.activatedLogin, true);
      });
    });
    const newLoginAccesses = [];
    this.loginsTab.map(item=>{
      if (self.initialAccess.loginAccess.includes(item.id) && item.isSelected === false) {
        newLoginAccesses.push(new LoginLoginAccess(self.initialAccess.loginID, item.id, 0));
      }
      if (!self.initialAccess.loginAccess.includes(item.id) && item.isSelected === true) {
        newLoginAccesses.push(new LoginLoginAccess(self.initialAccess.loginID, item.id, 1));
      }
    })
    console.log(newLoginAccesses);
    
    this.http.post<boolean>(this.baseUrl + 'api/Access/UpdateLoginAccess', newLoginAccesses).subscribe(result => {
      console.log(result);
      document.getElementById('openAccessUpdateModal').click();
      self.toggleSelected(self.activatedLogin, true);
    });
  }

  openCreateUserModal() {
    this.activatedLogin = new Login();
    if(localStorage.getItem('role') == '2' || localStorage.getItem('role') == '1'){
      this.isUnitDisabled = false
    }else {
      this.isUnitDisabled = true
    }
  }

  getUsers(){
    if(localStorage.getItem('company_id')){
      const parameters = {
        login_id: localStorage.getItem('user_id')
      }
      this.http.get<LoginLoginAccess[]>(this.baseUrl + 'api/Login/getLoginLogins', { params: parameters} ).subscribe(r=>{
        const loginsAr = []
        r.map(x=>{
          loginsAr.push(x.to_login_id)
        })
        const params = {
          company_id: localStorage.getItem('company_id'),
          logins: loginsAr
        } 
        this.getLoginsCompany(params).subscribe(result => {
          this.logins = result;
          this.logins.map(function (item) {
            item.roleName = item.role == 0 ? 'Super' : item.role == 1 ? 'Admin' : 'User'  
            item.statusName = item.status == 0 ? 'Active' : item.status == 1 ? 'Block' : 'Del'  
            item.isSelected = false;
          });
        });
      })

    }else{
      this.getLogins()
    }
  }

  addLogin() {

    this.activatedLogin.author = +localStorage.getItem('user_id')

    if(localStorage.getItem('role') == '0'){
      return this.http.post<Login[]>(this.baseUrl + 'api/Login/LoginCreate', this.activatedLogin).subscribe(result => {
        this.logins = result;
        this.activatedLogin.group_id = null
        this.getUsers()
      });
    }else{
      this.activatedLogin.company_id = Number(localStorage.getItem('company_id'))
      this.activatedLogin.role = 2
      return this.http.post<Login[]>(this.baseUrl + 'api/Login/LoginCreate', this.activatedLogin).subscribe(result => {
        this.logins = result;
        this.activatedLogin.group_id = null
        this.getUsers()
      });
    }

  }

  updateLogin() {
    if (this.activatedLogin.newPass && this.activatedLogin.newPass != '') {
      const hash = sha256.create();
      hash.update(this.activatedLogin.newPass);
      hash.hex();
      this.activatedLogin.pass = hash.toString().toUpperCase();
      const params = {
        id: this.activatedLogin.id,
        pass: this.activatedLogin.pass,
        author: Number(localStorage.getItem('user_id'))
      }
      
      this.http.post(this.baseUrl + 'api/Login/LoginPasswordUpdate', params).subscribe(r=>console.log(r)
      )
    }
    const params = {
      id: this.activatedLogin.id,
      login1: this.activatedLogin.login1,
      name: this.activatedLogin.name,
      status: this.activatedLogin.status,
      group_id: this.activatedLogin.group_id,
      author: Number(localStorage.getItem('user_id'))
    }

    return this.http.post<Login[]>(this.baseUrl + 'api/Login/LoginUpdate', params).subscribe(() => {
      this.getUsers();
    });
  }


  editRules(e){
    const params = {
      id: e.id
    }
    
    this.http.post<LoginUserAccess>(this.baseUrl + 'api/Access/GetLoginUserAccesses', params).subscribe(r=>{
      this.activeUserSettings = r[0];
      this.editUserSetting = true;

      console.log(this.isSameRole)
      console.log(this.activeUserSettings)

      debugger;

      if (this.global.userRole === 0) {

        if (this.activeUserSettings.id == this.global.userId) {
          this.isSameRole = true;
          this.isAdminRole = true;
          return;
        }

        if (this.logins.find(log => log.id === this.activeUserSettings.id).role == 1) {
          this.isAdminRole = true;
          this.isSameRole = false;
          return;
        }
      }

      this.isSameRole = false;
      this.isAdminRole = false;

    })
  }

  updateRules() {

    const params = {
      id: this.activatedLogin.id,
      login_acc: this.activeUserSettings.login_acc,
      device_acc: this.activeUserSettings.device_acc,
      key_acc: this.activeUserSettings.key_acc,
      task_acc: this.activeUserSettings.task_acc,
      formatkey_acc: this.activeUserSettings.formatkey_acc,
      author: Number(localStorage.getItem('user_id'))
    }

    if (this.isSameRole) {

      debugger;

      this.global.formatkey_acc = this.activeUserSettings.formatkey_acc;
      localStorage.setItem('formatkey_acc', this.activeUserSettings.formatkey_acc.toString());
    }

    this.http.post(this.baseUrl + 'api/Access/LoginAccessUpdate', params).subscribe()
    this.editUserSetting = false
  }

  toggleActiveGroup(type) {

    if(type == 'key'){
      
      if(this.activeKeyGroup == -1){
        this.keys.map(x=> x.isSelected = false)
      }else{
        const params = {
          id: this.activeKeyGroup
        }
        this.http.post<KeysInGroup[]>(this.baseUrl + 'api/Access/GetKeysInGroup', params).subscribe(r => {
          r.map(groupKeyItem=>{
            this.keys.map(KeyItem=>{
              if(KeyItem.id == groupKeyItem.keyId){
                KeyItem.isSelected = true
              } 
            })
          })
        })
      }
    } else {
      this.toggleDevicesMode = !this.toggleDevicesMode;
      if(this.activeDevGroup == -1){
        this.devices.map(x=> x.isSelected = false)
      }else{
        const params = {
          id: this.activeDevGroup
        }
        this.http.post<DevicesInGroup[]>(this.baseUrl + 'api/Access/GetDevicesInGroup', params).subscribe(r => {
          r.map(groupDeviceItem=>{
            this.devices.map(DeviceItem=>{
              if(DeviceItem.id == groupDeviceItem.deviceId){
                DeviceItem.isSelected = true
              } 
            })
          })
        })
      }
    }
  }

}

class Login {
  public id: number;
  public name: string;
  public login1: string;
  public pass: string;
  public newPass: string;
  public role: number = 1;
  public roleName: string;
  public statusName: string;
  public status: number;
  public isSelected = false;
  public company_id: number;
  public group_id: any = null;
  public author: any;
  public companyName: string;
  public groupName: string;
}

class LoginUserAccess {
  id: number;
  company_acc: number;
  group_acc: number;
  login_acc: number;
  device_acc: number;
  key_acc: number;
  task_acc: number;
  formatkey_acc: number;
}

class LoginAccess {
  loginID: number;
  deviceAccess: number[];
  keyAccess: number[];
  loginAccess: number[];
}
class LoginDeviceAccess {
  LoginID: number;
  DeviceID: number;
  Access: number;
  constructor(loginId: number, deviceId: number, access: number) {
    this.LoginID = loginId;
    this.DeviceID = deviceId;
    this.Access = access;
  }
}
class LoginLoginAccess {
  login_id: number;
  to_login_id: number;
  access: number;
  constructor(loginId: number, deviceId: number, access: number) {
    this.login_id = loginId;
    this.to_login_id = deviceId;
    this.access = access;
  }
}
class LoginKeyAccess {
  LoginID: number;
  KeyId: number;
  Access: number;
  constructor(loginId: number, keyId: number, access: number) {
    this.LoginID = loginId;
    this.KeyId = keyId;
    this.Access = access;
  }
}
class DevGroup{
  id: number;
  name: string;
}
class KeyGroup{
  id: number;
  name: string;
}
class KeysInGroup{ 
  keyId: number
}
class DevicesInGroup{
  deviceId: number
}
