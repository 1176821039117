import { Component, OnInit } from '@angular/core';
import Company from '../models/Company';
import {CompanyService} from '../services/company.service';
import CompanyUnit from '../models/CompanyUnit';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {


  public addType: number = 0
  public editCompany: boolean = false;
  public editCompanyUnit: boolean = false;

  public companies: Company[]
  public company: Company = {
    id: null,
    name: '',
    description: ''
  } 
  public activeCompany: Company = {
    id: null,
    name: '',
    description: ''
  }
  
  public companyUnits: CompanyUnit[]
  public companyUnit: CompanyUnit = {
    id: null,
    name: '',
    description: '',
    company_id: null,
    author: this.getCookie('UserId'),
  }
  public activeCompanyUnit: CompanyUnit = {
    id: null,
    name: '',
    description: '',
    company_id: null,
    author: this.getCookie('UserId')
  }
  
  //pagination
  public p1: number = 1;
  public p: number = 1;
  public itemsPerPageCompany = 10; 
  public itemsPerPageCompanyUnit = 10;

  //search
  public searchCompany: '';
  public searchCompanyUnit: '';
  
  public userRole: number;
  constructor(
    private companyService: CompanyService,
  ){ 
    this.userRole = +localStorage.getItem('role')

    if(this.userRole == 1){
      this.companyService.getCompany({id: Number(localStorage.getItem('company_id'))}).subscribe(r => this.companies = r)
    }else{
      this.companyService.getCompanies().subscribe(r => this.companies = r)
    }


  }

  ngOnInit() {

  }


  createCompany(){
    const params = {
      name: this.company.name,
      description: this.company.description
    }
    this.companyService.createCompany(params).subscribe(r => this.companies = r)
  }

  setActiveCompany(e){
    this.activeCompany = e
    const params = {
      company_id: this.activeCompany.id
    }
    this.companyService.getCompanyUnits(params).subscribe(r=>this.companyUnits = r)
  }

  editCompanyFn(e){
    this.activeCompany = e
    this.editCompany = true
  }

  updateCompany(){
    this.companyService.updateCompany(this.activeCompany).subscribe(r => {
      this.companies = r 
      this.editCompany = false
    })
  }

  deleteCompany(e){
    if(confirm("Вы действительно хотите удалить компанию " + e.name + "?")) {
      this.companyService.deleteCompany(e).subscribe(r => {
        this.companies = r
        this.companyUnits = []
      })
    }
  }

  createCompanyUnit(){
    this.companies.map(x=>{
      if(x.id == this.companyUnit.company_id){
        this.activeCompany = x
      }
    })
    this.companyService.createCompanyUnit(this.companyUnit).subscribe(r=>{
      this.companyUnits = r
      const params = {
        company_id: this.activeCompany.id
      }
      this.companyService.getCompanyUnits(params).subscribe(r=>this.companyUnits = r)
    })
  }

  updateCompanyUnit(){
    console.log(this.activeCompanyUnit)
    this.companyService.updateCompanyUnit(this.activeCompanyUnit).subscribe(r=>{
      this.companyUnits = r
      this.editCompanyUnit = false
    })    
  }

  ediCompanyUnitFn(e){
    this.activeCompanyUnit = e
    this.editCompanyUnit = true
  }

  deleteCompanyUnit(e){
    if(confirm("Вы действительно хотите удалить подразделение " + e.name + "?")) {

      this.companyService.deleteCompanyUnit(e).subscribe(r => this.companyUnits = r)
      console.log(this.companyUnits);

      const params = {
        company_id: e.company_id
      }

      this.companyService.getCompanyUnits(params).subscribe(r => this.companyUnits = r)
    }
  }

  getCookie(name){
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
}
