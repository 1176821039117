import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from "@angular/router";
import { Global } from "../global";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  public authModel: AuthorizationModel = {
    login1: '',
    password: ''
  };
  public errorMessage: string = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private router: Router, private global: Global) {


  }

  ngOnInit() {
  }

  public Authorize(): any {
    const body = {
      login1: this.authModel.login1,
      password: this.authModel.password
    }

    this.http.post<AuthorizationResult>(this.baseUrl + 'api/Auth/Login', body).subscribe(result => {
      if (result.status != 0) {
        if (result.status == -1) {
          this.errorMessage = "Данные пользователя введены неверно";
        }
        else if (result.status == 1) {
          this.errorMessage = "Пользователь заблокирован";
        }
        else if (result.status == 2) {
          this.errorMessage = "Пользователь удален";
        }
        else {
          this.errorMessage = "Ошибка авторизации";
        }
      }
      else {
        localStorage.setItem("role", result.userRole.toString());
        localStorage.setItem("user_id", result.user_id.toString());
        if (result.company_id) {
          localStorage.setItem("company_id", result.company_id.toString());
        }

        this.global.userRole = result.userRole;
        this.global.userId = result.user_id;

        this.http.post<LoginUserAccess>(this.baseUrl + 'api/Access/GetLoginUserAccesses', {id: result.user_id}).subscribe(result => {

          if(result[0]){
            this.global.login_acc = result[0].login_acc
            this.global.device_acc = result[0].device_acc
            this.global.key_acc = result[0].key_acc
            this.global.task_acc = result[0].task_acc
            this.global.formatkey_acc = result[0].formatkey_acc
            localStorage.setItem('login_acc', result[0].login_acc.toString());
            localStorage.setItem('device_acc', result[0].device_acc.toString());
            localStorage.setItem('key_acc', result[0].key_acc.toString());
            localStorage.setItem('task_acc', result[0].task_acc.toString());
            localStorage.setItem('formatkey_acc', result[0].formatkey_acc.toString());
          } 
         
          this.router.navigate(['/cards']);
        });
        

      }
    }, error => {
      this.errorMessage = "Ошибка авторизации";
      console.error(error);
    });
  }

}

interface AuthorizationModel {
  login1: string;
  password: string;
}

interface AuthorizationResult {
  status: number;
  userRole: number;
  company_id: number;
  user_id: number;
}

class LoginUserAccess {
  user_id: number;
  login_acc: number;
  device_acc: number;
  key_acc: number;
  task_acc: number;
}
