import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDevices',
  pure: false
})
export class SearchDevicesPipe implements PipeTransform {

  transform(items: any[], criteria: any): any {

    let props = ['imei', 'name', 'rfaddr', 'description' ];
     
    if(!items){
      return false
    }
    return items.filter(item => {
      if (!criteria) {
        return true;
      }
      for (let key in props) {        
        if (item[props[key]] && ("" + item[props[key]]).toLocaleLowerCase().includes(criteria.toLocaleLowerCase())) {
          item.isSearched = true
          return true; 
        }else{
          item.isSearched = false
        }
      }
      return false;
    });
  }

}
