import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchLoggings',
  pure: false
})
export class SearchLoggingsPipe implements PipeTransform {

  transform(items: any[], criteria: any): any {

    let props = ['date', 'internalNum', 'externalNum', 'description', 'statusName', 'author', 'groupName', 'externalNum', 'groupActionName',
      'imei', 'ipspass', 'bootPass', 'name', 'rfaddr', 'statusName',
      'login', 'roleName'];

    return items.filter(item => {
      if (!criteria) {
        return true;
      }
      for (let key in props) {
        if (item[props[key]] && ("" + item[props[key]]).toLocaleLowerCase().includes(criteria.toLocaleLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }

}
