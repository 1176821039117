import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companySearch',
})
export class CompanySearchPipe implements PipeTransform {


  transform(items: any[], criteria: any): any {

    let props = ['name', 'description'];
    
    if(!items){
      return false
    }
    return items.filter(item => {
      if (!criteria) {
        return true;
      }
      for (let key in props) {        
        if (item[props[key]] && ("" + item[props[key]]).toLocaleLowerCase().includes(criteria.toLocaleLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }

}
