import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { OrderPipe } from 'ngx-order-pipe';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Device } from '../device';
import { Global } from '../global';
import { Key } from '../key';
import { CompanyService } from '../services/company.service';
import { Task } from '../task';
import { UsersComponent } from '../users/users.component';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  // User
  public users: UsersComponent;
  public logins: Login[] = [];

  public cardGroupsShowed = true;
  public trekerGroupsShowed = true;
  public autoExtNum = false;
  public autoIntNum = false;
  public defaultIpsPass = '';
  public defaultBootPass = '';
  public defaultRfAddr = '';

  public searchKey = '';
  public searchDevice = '';
  public searchKeyGroup = '';
  public searchDeviceGroup = '';
  public taskSearchFilter = '';

  //pagination
  public p1: number = 1;
  public p: number = 1;
  public itemsPerPageCards = 15;
  public itemsPerPageDevices = 15;

  // initial arrays
  public keys: Key[] = [];
  public keyGroups: GroupKey[] = [];
  // Todo Исправить переменную
  public keyAllGroups: GroupKey[] = [];
  public devices: Device[] = [];
  public deviceGroups: GroupDevice[] = [];
  public deviceAllGroups: GroupDevice[] = [];

  public selectedEntityType: string;
  public activatedEntity: any = { id: 0, type: '', data: {} };
  public activeKey: Key = new Key();
  public activeDevice: Device = new Device();
  public activeKeyGroup: GroupKey = new GroupKey();
  public activeDeviceGroup: GroupDevice = new GroupDevice();
  public activeModule: Module = new Module();

  public toggleKeysMode = false;
  public toggleKeyGroupsMode = false;
  public toggleDevicesMode = false;
  public toggleDeviceGroupsMode = false;


  //accessses///
  // divece access
  public isTrekerAccessDenay: boolean = true;
  public isTrekerAccessRead: boolean = false;
  public isTrekerAccessEdit: boolean = false;
  public isTrekerAccessCreate: boolean = false;
  // card access
  public isKeyAccessDenay: boolean = true;
  public isKeyAccessRead: boolean = false;
  public isKeyAccessEdit: boolean = false;
  public isKeyAccessCreate: boolean = false;

  public editActive: boolean = false;
  public deleteActive: boolean = false;
  // task access
  public isTaskAccess: boolean = true;
  public isTaskAccessDeleteCards: boolean = true;

  public keysGroupFile: any;
  public devicesGroupFile: any;

  get isCurrentRoleIsUser() {
    return this.global.userRole === 2; // 2 is ID for user role
  }


  ngOnInit() {

    setTimeout(() => {
      if (this.global.task_acc > 2 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isTaskAccess = false
      }
    }, 1000);

    setTimeout(() => {
      if (this.global.task_acc > 2 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        if (this.global.formatkey_acc != 0) {
          this.isTaskAccessDeleteCards = false
        }
      }
    }, 1000);

    setTimeout(() => {
      if (this.global.device_acc != 0 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isTrekerAccessDenay = false
      }

      if (this.global.device_acc > 0 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isTrekerAccessRead = true
      }
      if (this.global.device_acc > 1 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isTrekerAccessEdit = true
      }
      if (this.global.device_acc > 2 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isTrekerAccessCreate = true
      }
    }, 1000);

    setTimeout(() => {

      if (this.global.key_acc != 0 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isKeyAccessDenay = false
      }

      if (this.global.key_acc > 0 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isKeyAccessRead = true
      }

      if (this.global.key_acc > 1 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isKeyAccessEdit = true
      }

      if (this.global.key_acc > 2 || +localStorage.getItem('role') == 0 || +localStorage.getItem('role') == 1) {
        this.isKeyAccessCreate = true
      }


    }, 1000);

  }


  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    companyService: CompanyService,
    public global: Global,
    private router: Router,
    private orderPipe: OrderPipe,
  ) {

    /* Todo Это костыль */
    this.users = new UsersComponent(http, baseUrl, companyService, global);

    this.logins = this.users.logins;
    /* Конец */

    http.get<Key[]>(this.baseUrl + 'api/Keys/GetKeys').subscribe(result => {
      this.keys = result.map(function (item) { item.isSelected = false; item.isSearched = true; return item; });
    }, error => {
      console.log(error);
    });
    http.get<GroupKey[]>(this.baseUrl + 'api/GroupKey/GetGroupKey').subscribe(result => {
      this.keyGroups = result.map(function (item) { item.isSelected = false; item.isSearched = true; return item; });
    }, error => {
      console.log(error);
    });
    http.get<Device[]>(this.baseUrl + 'api/Devices/GetDevices').subscribe(result => {
      this.devices = result.map(function (item) { item.isSelected = false; item.isSearched = true; return item; });
      this.devices = this.devices.map(function (item) {
        const date = new Date();
        if (item.lastPoint) {
          var duration = moment.duration(moment().diff(moment.utc(item.lastPoint)));
          var lastPointDifferenceInMinutes = duration.asMinutes();
          console.log(lastPointDifferenceInMinutes, item.lastPoint);
          if (lastPointDifferenceInMinutes < 30) {
            item.isLive = true;
          }
        }
        return item;
      });
    }, error => {
      console.log(error);
    });
    http.get<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/GetGroupDev').subscribe(result => {
      this.deviceGroups = result.map(function (item) { item.isSelected = false; item.isSearched = true; return item; });
    }, error => {
      console.log(error);
    });

    this.http.get<GroupKey[]>(this.baseUrl + 'api/GroupKey/GetAllGroupKey').subscribe(r => this.keyAllGroups = r)
    this.http.get<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/GetAllGroupDev').subscribe(r => this.deviceAllGroups = r)
  }

  goByLink(linkText) {
    this.global.linkFilter = linkText;
    this.router.navigate(['/tasks']);
  }

  toggle(S, x) {
    // tslint:disable-next-line:no-bitwise
    S[x] = 1 - (S[x] | 0);
  }

  createTasks(type) {
    const self = this;
    const tasks = [];
    const keys = this.keys.filter(x => x.isSelected === true);
    const devices = this.devices.filter(x => x.isSelected === true);
    keys.map(function (key) {
      devices.map(function (device) {
        const task: Task = {} as Task;
        task.keyID = key.id;
        task.moduleID = device.moduleId;
        task.type = type;
        task.author = self.global.userId;
        tasks.push(task);
      });
    });
    this.http.post<Task[]>(this.baseUrl + 'api/Tasks/CreateTasks', tasks).subscribe(result => {
      const self = this;
    });
  }

  removeCardsTaskCreate() {
    const self = this;
    const tasks = [];
    const devices = this.devices.filter(x => x.isSelected === true);
    devices.map(function (device) {
      const task: Task = {} as Task;
      task.keyID = null;
      task.moduleID = device.moduleId;
      task.type = 0; // for delete
      task.author = self.global.userId;
      tasks.push(task);
    });
    this.http.post<Task[]>(this.baseUrl + 'api/Tasks/CreateTasks', tasks).subscribe(result => {
      const self = this;
    });
  }

  clearSearchedItems(items) {
    if (this.searchKey == '') {
      items.map(x => x.isSearched = true)
    }
  }

  toggleSelected(type) {

    const self = this;

    switch (type) {
      case 'key':
        this.toggleKeysMode = !this.toggleKeysMode;
        this.keys.map(function (item) {
          if (item.isSearched) {
            item.isSelected = self.toggleKeysMode;
          }
        });
        break;
      case 'device':
        this.toggleDevicesMode = !this.toggleDevicesMode;
        this.devices.map(function (item) {
          if (item.isSearched) {
            item.isSelected = self.toggleDevicesMode;
          }
        });
        break;
      case 'keyGroup':
        this.toggleKeyGroupsMode = !this.toggleKeyGroupsMode;
        this.keyGroups.map(function (item) {
          if (item.isSearched) {
            item.isSelected = self.toggleKeyGroupsMode;
          }

          if (item.isSelected === true) {
            self.selectGroup('keyGroup', item);
          }
        });
        break;
      case 'deviceGroup':
        this.toggleDeviceGroupsMode = !this.toggleDeviceGroupsMode;
        this.deviceGroups.map(function (item) {

          if (item.isSearched) {
            item.isSelected = self.toggleDeviceGroupsMode;
          }
          if (item.isSelected === true) {
            self.selectGroup('deviceGroup', item);
          }
        });
        break;
    }

    this.toggleActiveButtuns(type);

  }

  selectGroup(type, entity) {
    let apiUrl = '';
    if (!entity.isSelected) {
      return;
    }
    switch (type) {
      case 'keyGroup': apiUrl = 'GetLinksForKeyGroup'; break;
      case 'deviceGroup': apiUrl = 'GetLinksForDeviceGroup'; break;
    }
    return this.http.post<Link>(this.baseUrl + 'api/Links/' + apiUrl, entity.id).subscribe(result => {
      const self = this;
      result.keyIds.map(function (item) {
        const res = self.keys.find(x => x.id === item.id);
        if (res) {
          res.isSelected = true;
        }
      });
      result.deviceIds.map(function (item) {
        const res = self.devices.find(x => x.id === item.id);
        if (res) {
          res.isSelected = true;
        }
      });

    });
  }

  hexToDec(value) {
    return parseInt(value.toString(), 16);
  }

  decToHexPad(value) {
    return (+value ? +value : 0).toString(16).padStart(10, '0').toUpperCase();
  }

  setExtNum() {
    if (this.autoExtNum === true) {
      this.activeKey.externalNum = this.hexToDec(this.activeKey.internalNum) ? this.hexToDec(this.activeKey.internalNum).toString() : '';
    }
  }

  setIntNum() {
    if (this.autoIntNum) {
      this.activeKey.internalNum =
        this.decToHexPad(this.activeKey.externalNum) ? this.decToHexPad(this.activeKey.externalNum).toString() : '';
    }
  }

  setActivatedEntityNull() {
    this.activatedEntity.id = null;
    this.activatedEntity.type = null;
    this.activatedEntity.data = null;
  }

  toggleActiveButtuns(type) {

    console.log(this.global.key_acc);

    console.log(+localStorage.getItem('role'))

    let isAnyElementTrue = false;
    let isKeyAcceButtEdit = true;
    let isKeyAcceButtDelet = true;
    let isTrekerAcceButtEdit = true;
    let isTrekerAcceButtDelet = true;
    let coutCheckedKeys = 0;
    let coutCheckedDevices = 0;
    let entity;

    isAnyElementTrue = !!this.keys.find(el => el.isSelected);
    entity = this.keys.find(el => el.isSelected);

    if (!isAnyElementTrue) {
      isAnyElementTrue = !!this.keyGroups.find(el => el.isSelected);
      entity = this.keyGroups.find(el => el.isSelected);
    }

    if (!isAnyElementTrue) {
      isAnyElementTrue = !!this.devices.find(el => el.isSelected);
      entity = this.devices.find(el => el.isSelected);
    }

    if (!isAnyElementTrue) {
      isAnyElementTrue = !!this.deviceGroups.find(el => el.isSelected);
      entity = this.deviceGroups.find(el => el.isSelected);
    }

    if (!isAnyElementTrue) {
      this.deleteActive = false;
      this.editActive = false;
      this.setActivatedEntityNull()
      return;
    }

    coutCheckedKeys += this.keys.filter(el => el.isSelected).length;
    coutCheckedKeys += this.keyGroups.filter(el => el.isSelected).length;

    if (coutCheckedKeys > 0) {
      if (this.isKeyAccessCreate) {
        isKeyAcceButtEdit = true;
        isKeyAcceButtDelet = true;
      }

      else if (this.isKeyAccessEdit) {
        isKeyAcceButtEdit = true;
        isKeyAcceButtDelet = false;
      }

      else {
        isKeyAcceButtEdit = false;
        isKeyAcceButtDelet = false;
      }
    }

    coutCheckedDevices += this.deviceGroups.filter(el => el.isSelected).length;
    coutCheckedDevices += this.devices.filter(el => el.isSelected).length;

    if (coutCheckedDevices > 0) {
      if (this.isTrekerAccessCreate) {
        isTrekerAcceButtEdit = true;
        isTrekerAcceButtDelet = true;
      }

      else if (this.isTrekerAccessEdit) {
        isTrekerAcceButtEdit = true;
        isTrekerAcceButtDelet = false;
      }

      else {
        isTrekerAcceButtEdit = false;
        isTrekerAcceButtDelet = false;
      }
    }

    this.deleteActive = isKeyAcceButtDelet && isTrekerAcceButtDelet;
    this.editActive = isKeyAcceButtEdit && isTrekerAcceButtEdit && (+coutCheckedKeys + +coutCheckedDevices) == 1;


    if (this.editActive) {
      this.activatedEntity.id = entity.id;
      this.activatedEntity.type = type;
      this.activatedEntity.data = entity;

      return;
    }

    this.setActivatedEntityNull()
  }

  toggleActiveCheked(type, entity) {

    let sameIdElement;

    if (type == 'key')
      sameIdElement = this.keys.find(el => el.id === entity.id);
    if (type == 'device')
      sameIdElement = this.devices.find(el => el.moduleId === entity.moduleId);

    if (sameIdElement) {
      sameIdElement.isSelected = !sameIdElement.isSelected;
    }


    this.toggleActiveButtuns(type);
  }


  toggleActive(type, entity) {

    //this.toggleActiveButtuns(type);

    if (type == 'device' || type == 'deviceGroup') {
      if (+localStorage.getItem('role') == 2) {
        if (+localStorage.getItem('device_acc') < 2) {
          // this.editActive = true
        } else {
          // this.editActive = false
        }
        if (+localStorage.getItem('device_acc') < 3) {
          // this.deleteActive = true
        } else {
          // this.deleteActive = false
        }
      } else {
        // this.deleteActive = false
        // this.editActive = false
      }
    }



    if (type == 'key' || type == 'keyGroup') {
      if (+localStorage.getItem('role') == 2) {
        if (+localStorage.getItem('key_acc') < 2) {
          // this.editActive = true
        } else {
          // this.editActive = false
        }
        if (+localStorage.getItem('key_acc') < 3) {
          // this.deleteActive = true
        } else {
          // this.deleteActive = false
        }
      } else {
        // this.deleteActive = false
        // this.editActive = false
      }
    }


    this.keys.map(function (item) {
      item.isActive = false;
      item.activeStatus = 0;
    });
    this.devices.map(function (item) {
      item.isActive = false;
      item.activeStatus = 0;
    });
    this.keyGroups.map(item => item.isActive = false);
    this.deviceGroups.map(item => item.isActive = false);
    if (this.activatedEntity.id === entity.id && this.activatedEntity.type === type) {
      this.activatedEntity = { id: 0, type: '', data: null };
      this.toggleActiveButtuns(type);
      return;
    }

    let apiUrl = '';

    switch (type) {
      case 'key': apiUrl = 'GetLinksForKey'; break;
      case 'device': apiUrl = 'GetLinksForModule'; break;
      case 'keyGroup': apiUrl = 'GetLinksForKeyGroup'; break;
      case 'deviceGroup': apiUrl = 'GetLinksForDeviceGroup'; break;
    }


    return this.http.post<Link>(this.baseUrl + 'api/Links/' + apiUrl, type === 'device' ? entity.moduleId : entity.id).subscribe(result => {
      const self = this;

      result.keyIds.map(function (item) {

        const res = self.keys.find(x => x.id === item.id);
        if (res) {
          res.isActive = true;
          res.activeStatus = item.status;
        }
      });
      result.deviceIds.map(function (item) {
        let res;

        if (type === "device") {
          res = self.devices.find(x => x.moduleId === item.id);

          if (res) {
            res.isActive = true;
            res.activeStatus = item.status;
          }
        } else {
          self.devices.map(function (itemDev) {
            if (itemDev.id === item.id) {
              itemDev.isActive = true;
              itemDev.activeStatus = item.status;
            }
          });
        }

      });
      result.deviceGroupIds.map(function (item) {
        const res = self.deviceGroups.find(x => x.id === item);
        if (res) { res.isActive = true; }
      });
      result.keyGroupIds.map(function (item) {
        const res = self.keyGroups.find(x => x.id === item);
        if (res) { res.isActive = true; }
      });
      if (type === 'keyGroup' || type === 'device') {
        this.keys = this.orderPipe.transform(this.keys, ['isActive', 'activeStatus'], true);
      }
      if (type === 'deviceGroup' || type === 'key') {
        this.devices = this.orderPipe.transform(this.devices, ['isActive', 'activeStatus'], true);
      }

      this.toggleActiveButtuns(type);
    });
  }

  createEntity() {

    if (this.selectedEntityType === 'key') {
      return this.http.post<Key[]>(this.baseUrl + 'api/Keys/AddKey', this.activeKey).subscribe(result => {
        this.keys = result;
        this.activeKey = new Key();
      });
    }

    if (this.selectedEntityType === 'device') {
      return this.http.post<Device[]>(this.baseUrl + 'api/Devices/AddDevice', this.activeDevice).subscribe(result => {
        this.devices = result;
        this.devices = this.devices.map(function (item) {
          const date = new Date();
          if (item.lastPoint) {
            const lastDate = new Date(item.lastPoint);
            const diff = Math.abs(lastDate.getTime() - date.getTime());
            if (diff < 600000 + 7200000) {
              item.isLive = true;
            }
          }
          return item;
        });
        this.activeDevice = new Device();
      });
    }

    if (this.selectedEntityType === 'module') {
      return this.http.post<Device[]>(this.baseUrl + 'api/Devices/AddModule', this.activeModule).subscribe(result => {
        this.devices = result;
        this.activeModule = new Module();
      });
    }

    if (this.selectedEntityType === 'keyGroup') {
      return this.http.post<GroupKey[]>(this.baseUrl + 'api/GroupKey/AddKeyGroup', this.activeKeyGroup).subscribe(result => {
        //console.log(result);
        this.keyGroups = result;
        this.activeKeyGroup = new GroupKey();
      });
    }

    if (this.selectedEntityType === 'deviceGroup') {
      return this.http.post<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/AddDeviceGroup', this.activeDeviceGroup).subscribe(result => {
        this.deviceGroups = result;
        this.activeDeviceGroup = new GroupDevice();
      });
    }
  }



  updateEntity() {


    if (this.activatedEntity.type === 'key') {
      return this.http.post<Key[]>(this.baseUrl + 'api/Keys/UpdateKey', this.activatedEntity.data).subscribe(result => {
        this.keys = result;
        this.toggleActive('key', this.activatedEntity.data);
      });
    }
    if (this.activatedEntity.type === 'device') {
      return this.http.post<Device[]>(this.baseUrl + 'api/Devices/UpdateDevice', this.activatedEntity.data).subscribe(result => {
        this.devices = result;
        this.devices = this.devices.map(function (item) {
          const date = new Date();
          if (item.lastPoint) {
            const lastDate = new Date(item.lastPoint);
            const diff = Math.abs(lastDate.getTime() - date.getTime());
            if (diff < 600000 + 7200000) {
              item.isLive = true;
            }
          }
          return item;
        });
        this.toggleActive('device', this.activatedEntity.data);
      });
    }
    if (this.activatedEntity.type === 'keyGroup') {
      return this.http.post<GroupKey[]>(this.baseUrl + 'api/GroupKey/UpdateKeyGroup', this.activatedEntity.data).subscribe(result => {
        //console.log(result);
        this.keyGroups = result;
        this.toggleActive('keyGroup', this.activatedEntity.data);
      });
    }
    if (this.activatedEntity.type === 'deviceGroup') {
      return this.http.post<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/UpdateDeviceGroup', this.activatedEntity.data).subscribe(result => {
        this.deviceGroups = result;
        this.toggleActive('deviceGroup', this.activatedEntity.data);
      });
    }


    // this.toggleActiveButtuns(this.activatedEntity.type);

  }

  deleteEntities() {
    const activatedKeys = this.keys.filter(x => x.isSelected === true);

    if (activatedKeys.length > 0) {
      activatedKeys.map(x => x.status = 2);
      this.http.post<Key[]>(this.baseUrl + 'api/Keys/UpdateKeys?userId=' + Number(localStorage.getItem('user_id')), activatedKeys).subscribe(result => {
        this.keys = result;
        this.toggleActive('key', this.activatedEntity.data);

        this.toggleActiveButtuns(null);
      });
    }
    const activatedDevices = this.devices.filter(x => x.isSelected === true);
    if (activatedDevices.length > 0) {
      activatedDevices.map(x => x.status = 2);
      this.http.post<Device[]>(this.baseUrl + 'api/Devices/UpdateDevices?userId=' + Number(localStorage.getItem('user_id')), activatedDevices).subscribe(result => {
        this.devices = result;
        this.toggleActive('device', this.activatedEntity.data);

        this.toggleActiveButtuns(null);
      });
    }
    const activatedKeyGroups = this.keyGroups.filter(x => x.isSelected === true);
    if (activatedKeyGroups.length > 0) {
      this.http.post<GroupKey[]>(this.baseUrl + 'api/GroupKey/DeleteKeyGroups?userId=' + Number(localStorage.getItem('user_id')), activatedKeyGroups).subscribe(result => {
        this.keyGroups = result;
        this.activatedEntity = { id: 0, type: '', data: null };

        this.toggleActiveButtuns(null);
      });
    }
    const activatedDeviceGroups = this.deviceGroups.filter(x => x.isSelected === true);
    if (activatedDeviceGroups.length > 0) {
      this.http.post<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/DeleteDeviceGroups?userId=' + Number(localStorage.getItem('user_id')), activatedDeviceGroups).subscribe(result => {
        this.deviceGroups = result;
        this.activatedEntity = { id: 0, type: '', data: null };

        this.toggleActiveButtuns(null);
      });
    }
  }

  updateGroupLinks(mode, type) {

    let apiURL = 'api/Links/' + mode + type;
    if (mode === 'Remove') {
      apiURL += 'FromGroup';
    } else {
      apiURL += 'ToGroup';
    }

    let data = {};
    if (type === 'Keys') {
      data = {
        userId: Number(localStorage.getItem('user_id')),
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        groupIDs: this.keyGroups.filter(x => x.isSelected === true).map(item => item.id),
        iDS: this.keys.filter(x => x.isSelected === true).map(item => item.id)
      };
    } else {
      data = {
        userId: Number(localStorage.getItem('user_id')),
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        groupIDs: this.deviceGroups.filter(x => x.isSelected === true).map(item => item.id),
        iDS: this.devices.filter(x => x.isSelected === true).map(item => item.id)
      };
    }

    // console.log(data);
    return this.http.post<object>(this.baseUrl + apiURL, data).subscribe(result => {
      // console.log(result);
    });
  }

  refreshLists() {
    this.http.get<Key[]>(this.baseUrl + 'api/Keys/GetKeys').subscribe(result => {
      this.keys = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
    this.http.get<GroupKey[]>(this.baseUrl + 'api/GroupKey/GetGroupKey').subscribe(result => {
      this.keyGroups = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
    this.http.get<Device[]>(this.baseUrl + 'api/Devices/GetDevices').subscribe(result => {
      this.devices = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
    this.http.get<GroupDevice[]>(this.baseUrl + 'api/GroupDevice/GetGroupDev').subscribe(result => {
      this.deviceGroups = result.map(function (item) { item.isSelected = false; return item; });
    }, error => {
      console.log(error);
    });
  }

  uploadDevices() {
    try {
      const input: HTMLInputElement = document.getElementById('fileInputDevices') as HTMLInputElement;

      const self = this;
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        // console.log('CSV: ', text.substring(0, 100) + '...');

        const devicesFromFile = this.csvJSON(text);
        if (this.defaultBootPass && this.defaultBootPass !== '') {
          devicesFromFile.map(function (item) {
            item.BOOTpass = self.defaultBootPass;
          });
        }
        if (this.defaultIpsPass && this.defaultIpsPass !== '') {
          devicesFromFile.map(function (item) {
            item.IPSpass = self.defaultIpsPass;
          });
        }
        if (this.defaultRfAddr && this.defaultRfAddr !== '') {
          devicesFromFile.map(function (item) {
            item.Rfaddr = self.defaultRfAddr;
          });
        }

        devicesFromFile.map(x => {
          x.group_id = this.devicesGroupFile
        })

        return this.http.post<Device[]>(this.baseUrl + 'api/Devices/AddDevices', devicesFromFile).subscribe(result => {
          this.devices = result;
          this.devices = this.devices.map(function (item) {
            const date = new Date();
            if (item.lastPoint) {
              const lastDate = new Date(item.lastPoint);
              const diff = Math.abs(lastDate.getTime() - date.getTime());
              if (diff < 600000 + 7200000) {
                item.isLive = true;
              }
            }
            return item;
          });
          this.activeDevice = new Device();
          self.openResultModal('success');
        }, function (error) {
          self.openResultModal('error');
        });
      };
      reader.readAsText(input.files[0]);
    } catch (error) {
      // self.openResultModal('error');
    }
  }

  uploadKeys() {
    const self = this;
    try {
      const input: HTMLInputElement = document.getElementById('fileInputKeys') as HTMLInputElement;

      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        // console.log('CSV: ', text.substring(0, 100) + '...')
        const keysFromFile = this.csvJSON(text)
        if (this.autoExtNum) {
          keysFromFile.map(function (key) {
            key.ExternalNum = self.hexToDec(key.InternalNum).toString()
          })
        }
        if (this.autoIntNum) {
          keysFromFile.map(function (key) {
            key.InternalNum = self.decToHexPad(key.ExternalNum).toString()
          })
        }

        if (this.keysGroupFile) {
          keysFromFile.map(x => {
            x.group_id = this.keysGroupFile
          })
        }


        return this.http.post<Key[]>(this.baseUrl + 'api/Keys/AddKeys', keysFromFile).subscribe(result => {
          this.keys = result
          this.activeKey = new Key()
          self.openResultModal('success')
        }, function (error) {
          self.openResultModal('error')
        });
      };
      reader.readAsText(input.files[0])
    } catch (error) {
      this.openResultModal('error')
    }
  }

  public csvJSON(csv) {
    const lines = csv.split('\n')

    const result = []

    const headers = lines[0].split(';')

    for (let i = 1; i < lines.length; i++) {
      if (lines[i] === '') {
        continue;
      }
      const obj = {};
      const currentline = lines[i].split(';');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].replace(/\r?\n?/g, '')] = currentline[j] ? currentline[j].replace(/\r?\n?/g, '') : '';
      }
      result.push(obj);

    }
    return result; // JavaScript object
    // return JSON.stringify(result); //JSON
  }

  openResultModal(type) {
    if (type === 'success') {
      document.getElementById('openEntitySuccessModal').click();
    }
    if (type === 'error') {
      document.getElementById('openEntityErrorModal').click();
    }
  }

  // START model and helper functions section for device typeahead
  public typeAheadDevice: any;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => !term || !term.length
        ? this.devices.slice(0, 10)
        : this.devices
          .filter(v => (v.name || '').toLowerCase().indexOf(term.toLowerCase()) > -1 || (v.imei || '').toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10))
    )

  formatter = (x: Device) => `${x.name || ''} (${x.imei})`;

  selectedTypeaheadItem(event: { item: Device }) {
    this.activeModule.deviceId = event.item.id;
  }
  // END model and helper functions section for device typeahead
}

class Login {
  public id: number;
  public name: string;
  public login1: string;
  public pass: string;
  public newPass: string;
  public role: number = 1;
  public roleName: string;
  public statusName: string;
  public status: number;
  public isSelected = false;
  public company_id: number;
  public group_id: any = null;
  public author: any;
  public companyName: string;
  public groupName: string;
}

class GroupKey {
  id: number;
  name: string;
  description: string;
  isActive = false;
  isSelected = false;
  isSearched: boolean = true;
}

class GroupDevice {
  id: number;
  name: string;
  description: string;
  isActive = false;
  isSelected = false;
  isSearched: boolean = true;
}

class Link {
  keyIds: any[];
  keyGroupIds: number[];
  deviceIds: any[];
  deviceGroupIds: number[];
}

class Module {
  deviceId: number;
  rfaddr: number;
  description: string;
}
