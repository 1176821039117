import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Task } from '../task';
import { Global } from '../global';
import * as moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
moment.locale('ru');

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  public tasks: Task[] = [];
  public taskFilter = '';
  public selectedTasks: Task[] = [];
  public p: number = 1;
  public tasksMode: boolean = true;

  public isTaskAccess: boolean = true;
  
  locale: LocaleConfig = {
    applyLabel: 'Выбрать',
    cancelLabel: 'Отменить',
    clearLabel: 'Очистить',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
  }

  ngOnInit() {
    if(+localStorage.getItem('task_acc') > 1 || +localStorage.getItem('role') == 1 || +localStorage.getItem('role') == 0){
      this.isTaskAccess = false
    }
    this.taskFilter = this.global.linkFilter;
  }

  //Datpicker
  public selectedDateRange: any;
  public datePickerMoment: any;
  maxDate: moment.Moment;
  minDate: moment.Moment;
  public ranges: any = {
    'За сегодня': [moment().startOf('day'), moment().endOf('day')],
    'За вчера': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
    'За последнии 7 дней': [moment().subtract(6, 'days'), moment()],
    'За последнии 30 дней': [moment().subtract(29, 'days'), moment()],
    'За этот месяц': [moment().startOf('month'), moment().endOf('month')],
    'За предыдущий месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
};

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private global: Global) {
    
    this.selectedDateRange = {
      startDate: moment().subtract(1,'days'),
      endDate: moment(),
    }

    this.datePickerMoment = {
      startDate: moment().subtract(1,'days'),
      endDate: moment(),
    }
    

    
    if(localStorage.getItem('role') == '0'){
      this.getAllTasks(this.selectedDateRange)
    }else{
      this.getUsersTasks(this.selectedDateRange)
    }
  }



  toggle(S, x) {
    // tslint:disable-next-line:no-bitwise
    S[x] = 1 - (S[x] | 0);
  }

  toggleActive(item: Task) {
    this.toggle(this.selectedTasks, item);
    item.isSelected = !item.isSelected;
  }

  stopTask(task: Task) {
    
    const params = {
      taskID: task.id
    }

    this.http.post<boolean>(this.baseUrl + 'api/Tasks/StopTask', task.id).subscribe(() => {
      this.refreshTasks()
    }, error => {
      console.log(error);
    });
  }

  stopAllTasks() {

    let Id = Number(localStorage.getItem('user_id'))

    this.http.post<boolean>(this.baseUrl + 'api/Tasks/StopAllTasks', Id).subscribe((r) => {
      console.log(r);
      
      this.refreshTasks()
    }, error => {
      console.log(error);
    });
  }

  refreshTasks() {
    if(localStorage.getItem('role') == '0'){
      this.getAllTasks(this.datePickerMoment)
    }else{
      this.getUsersTasks(this.datePickerMoment)
    }
  }

  updateDate(e){
    this.datePickerMoment = e
    console.log(this.datePickerMoment);
    
    if(localStorage.getItem('role') == '0'){
      this.getAllTasks(e)
    }else{
      this.getUsersTasks(e)
    }
  }

  getAllTasks(e){
    this.selectedDateRange = {
      start: e.startDate.format("YYYY-MM-DD HH:mm:ss"),
      finish: e.endDate.format("YYYY-MM-DD HH:mm:ss")
    }

    
    this.http.get<Task[]>(this.baseUrl + 'api/Tasks/GetTasks', {params: this.selectedDateRange }).subscribe(result => {

      result.length > 0 ? this.tasksMode = false : this.tasksMode = true

      this.tasks = result;
      this.tasks.map(function (item) {
        switch (item.status) {
          case -2: item.statusName = 'Ошибка'; break;
          case -1: item.statusName = 'Отмена'; break;
          case 0: item.statusName = 'Не начат'; break;
          case 1: item.statusName = 'Выполняется'; break;
          case 2: item.statusName = 'Выполнен'; break;
        }
        item.isSelected = false;
      });
    }, error => {
      console.log(error);
    });
  }

  getUsersTasks(e){

    const parameters = {
      loginId: localStorage.getItem('user_id')
    }

    this.http.get<Key[]>(this.baseUrl + 'api/Tasks/getUsersKeys', { params: parameters} ).subscribe(r=>{
      const keys = [];
      r.map(x=>{
        keys.push(x.keyId)
      })
      this.selectedDateRange = {
        keys: keys,
        start: e.startDate.format("YYYY-MM-DD HH:mm:ss"),
        finish: e.endDate.format("YYYY-MM-DD HH:mm:ss")
      }
      console.log(this.selectedDateRange);
      
      this.http.get<Task[]>(this.baseUrl + 'api/Tasks/GetUsersTasks', {params: this.selectedDateRange }).subscribe(result => {
        console.log(result);
        
        result.length > 0 ? this.tasksMode = false : this.tasksMode = true
  
        this.tasks = result;
        this.tasks.map(function (item) {
          switch (item.status) {
            case -2: item.statusName = 'Ошибка'; break;
            case -1: item.statusName = 'Отмена'; break;
            case 0: item.statusName = 'Не начат'; break;
            case 1: item.statusName = 'Выполняется'; break;
            case 2: item.statusName = 'Выполнен'; break;
          }
          item.isSelected = false;
        });
      })
    })
  }
}

class Key {
  keyId: number;
}
