import { Injectable } from '@angular/core';

export class Global {
  linkFilter: string = "";
  userRole: number = -1;
  userId: number;
  login_acc: number;
  device_acc: number;
  key_acc: number;
  task_acc: number;
  formatkey_acc: number;
}
