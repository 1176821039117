import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from './global';
import { filter } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [Global]
})
export class AppComponent implements OnInit {
  title = 'app';
  public router: Router;
  public globalData: Global;

  constructor(_router: Router, _global: Global) {
    this.router = _router;
    this.globalData = _global;
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {

      if (!this.getCookie("Authorization")) {
        this.router.navigate(['/login']);
      }
    });
  }

  getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
}
