import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Observable } from 'rxjs';

//models
import Company from '../models/Company';
import CompanyUnit from '../models/CompanyUnit';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }


  ///Company
  createCompany(parameters):Observable<Company[]> {
    return this.http.post<Company[]>(this.baseUrl + 'api/Company/CreateCompany', parameters)
  } 

  getCompanies():Observable<Company[]>{
    return this.http.get<Company[]>(this.baseUrl + 'api/Company/getCompanies')
  }

  getCompany(parameters):Observable<Company[]>{
    return this.http.post<Company[]>(this.baseUrl + 'api/Company/getCompany', parameters)
  }

  updateCompany(parameters):Observable<Company[]> {
    return this.http.post<Company[]>(this.baseUrl + 'api/Company/UpdateCompany', parameters)
  } 

  deleteCompany(parameters):Observable<Company[]> {
    return this.http.post<Company[]>(this.baseUrl + 'api/Company/DeleteCompany', parameters)
  }


  ///Company Unit
  createCompanyUnit(parameters):Observable<CompanyUnit[]> {
    delete parameters.id
    return this.http.post<CompanyUnit[]>(this.baseUrl + 'api/Company/CreateCompanyUnit', parameters)
  } 

  getCompanyUnits(parameters):Observable<CompanyUnit[]>{
    return this.http.get<CompanyUnit[]>(this.baseUrl + 'api/Company/getCompanyUnits', { params: parameters })
  }

  updateCompanyUnit(parameters):Observable<CompanyUnit[]> {
    return this.http.post<CompanyUnit[]>(this.baseUrl + 'api/Company/UpdateCompanyUnit', parameters)
  } 

  deleteCompanyUnit(parameters):Observable<CompanyUnit[]> {
    return this.http.post<CompanyUnit[]>(this.baseUrl + 'api/Company/DeleteCompanyUnit', parameters)
  }
}
