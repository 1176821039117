import { Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../global';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {


  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private global: Global, private router: Router) {
  }

  login_acc: number;
  task_acc: number;
  userRole: number;

  ngOnInit() {
    this.login_acc = +localStorage.getItem('login_acc')
    this.task_acc = +localStorage.getItem('task_acc')
    this.userRole = +localStorage.getItem('role')
  }

  public logout = function () {
    var self = this;
    this.http.get(this.baseUrl + 'api/Auth/Logout').subscribe(result => {
      localStorage.clear();
      self.router.navigate(['/login'])
    }, error => {
      localStorage.clear();
      self.router.navigate(['/login'])
    });
  }
}
 