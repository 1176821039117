import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Task } from '../task';
import { Global } from '../global';
import * as moment from 'moment';
moment.locale('ru');
import { logging } from 'protractor';
import { Data } from '@angular/router';
import { debug } from 'util';
import { LocaleConfig } from 'ngx-daterangepicker-material';


@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.css']
})

export class LoggingComponent implements OnInit {

  public loggingsKeys: LoggingKey[] = [];
  public loggingsDevices: LoggingDevice[] = [];
  public loggingsUsers: LoggingUser[] = [];
  public loggingFilter = '';
  public selectedLogging: LoggingKey[] = [];
  public pKey: number = 1;
  public pDevice: number = 1;
  public pUser: number = 1;
  public tasksModeKeys: boolean = true;
  public tasksModeDevices: boolean = true;
  public tasksModeUsers: boolean = true;

  public isTaskAccess: boolean = true;

  locale: LocaleConfig = {
    applyLabel: 'Выбрать',
    cancelLabel: 'Отменить',
    clearLabel: 'Очистить',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
  }

  ngOnInit() {
    if (+localStorage.getItem('task_acc') > 1 || +localStorage.getItem('role') == 1 || +localStorage.getItem('role') == 0) {
      this.isTaskAccess = false
    }
    this.loggingFilter = this.global.linkFilter;
  }

  //Datpicker
  public selectedDateRange: any;
  public datePickerMoment: any;
  maxDate: moment.Moment;
  minDate: moment.Moment;
  public ranges: any = {
    'За сегодня': [moment().startOf('day'), moment().endOf('day')],
    'За вчера': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
    'За последнии 7 дней': [moment().subtract(6, 'days'), moment()],
    'За последнии 30 дней': [moment().subtract(29, 'days'), moment()],
    'За этот месяц': [moment().startOf('month'), moment().endOf('month')],
    'За предыдущий месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private global: Global) {

    this.selectedDateRange = {
      userId: Number(localStorage.getItem('user_id')),
      startDate: moment().subtract(1, 'days'),
      endDate: moment()
    }

    this.datePickerMoment = {
      startDate: moment().subtract(1, 'days'),
      endDate: moment(),
    }

    this.getAllTasks(this.selectedDateRange)

    //if (localStorage.getItem('role') == '0') {
    //  this.getAllTasks(this.selectedDateRange)
    //} else {
    //  this.getUsersTasks(this.selectedDateRange)
    //}
  }



  toggle(S, x) {
    // tslint:disable-next-line:no-bitwise
    S[x] = 1 - (S[x] | 0);
  }

  toggleActive(item: LoggingKey) {
    this.toggle(this.selectedLogging, item);
    item.isSelected = !item.isSelected;
  }

  stopTask(task: Task) {

    const params = {
      taskID: task.id
    }

    this.http.post<boolean>(this.baseUrl + 'api/Tasks/StopTask', task.id).subscribe(() => {
      this.refreshTasks()
    }, error => {
      console.log(error);
    });
  }

  stopAllTasks() {

    let Id = Number(localStorage.getItem('user_id'))

    this.http.post<boolean>(this.baseUrl + 'api/Tasks/StopAllTasks', Id).subscribe((r) => {
      console.log(r);

      this.refreshTasks()
    }, error => {
      console.log(error);
    });
  }

  refreshTasks() {

    this.getAllTasks(this.datePickerMoment)

    //if (localStorage.getItem('role') == '0') {
    //  this.getAllTasks(this.datePickerMoment)
    //} else {
    //  //this.getUsersTasks(this.datePickerMoment)
    //}
  }

  updateDate(e) {
    this.datePickerMoment = e
    //console.log(this.datePickerMoment);

    this.getAllTasks(e)

    //if (localStorage.getItem('role') == '0') {
    //  this.getAllTasks(e)
    //} else {
    //  //this.getUsersTasks(e)
    //}
  }


  getAllTasks(e) {

    this.selectedDateRange = {
      userId: Number(localStorage.getItem('user_id')),
      start: e.startDate.format("YYYY-MM-DD HH:mm:ss"),
      finish: e.endDate.format("YYYY-MM-DD HH:mm:ss")
    }

    this.getLoggingKeys()
    this.getLoggingDevises();
    this.getLoggingUsers();


  }

  getLoggingKeys() {

    this.http.get<LoggingKey[]>(this.baseUrl + 'api/Logging/GetLoggingKeys', { params: this.selectedDateRange }).subscribe(result => {

      debugger;

      result.length > 0 ? this.tasksModeKeys = false : this.tasksModeKeys = true

      this.loggingsKeys = result;

      this.loggingsKeys.map(function (item) {

        switch (item.groupAction) {
          case 1: item.groupActionName = 'Добавлен'; break;
          case 0: item.groupActionName = 'Удален'; break;
        }

        switch (item.status) {
          case 0: item.statusName = 'Активный'; break;
          case 2: item.statusName = 'Удален'; break;
        }

        item.isSelected = false;
      });


    }, error => {
      console.log(error);
    });
  }

  getLoggingDevises() {

    debugger;

    this.http.get<LoggingDevice[]>(this.baseUrl + 'api/Logging/GetLoggingDevices', { params: this.selectedDateRange }).subscribe(result => {

      result.length > 0 ? this.tasksModeDevices = false : this.tasksModeDevices = true

      this.loggingsDevices = result;

      this.loggingsDevices.map(function (item) {

        switch (item.groupAction) {
          case 1: item.groupActionName = 'Добавлен'; break;
          case 0: item.groupActionName = 'Удален'; break;
        }

        switch (item.status) {
          case 0: item.statusName = 'Активный'; break;
          case 2: item.statusName = 'Удален'; break;
        }

        item.isSelected = false;
      });

      
      console.log(this.loggingsDevices);
     
    }, error => {
      console.log(error);
    });
  }

  getLoggingUsers() {

    this.http.get<LoggingUser[]>(this.baseUrl + 'api/Logging/GetLoggingUsers', { params: this.selectedDateRange }).subscribe(result => {

      result.length > 0 ? this.tasksModeUsers = false : this.tasksModeUsers = true

      this.loggingsUsers = result;

      this.loggingsUsers.map(function (item) {
        switch (item.status) {
          case 0: item.statusName = 'Активный'; break;
          case 2: item.statusName = 'Удален'; break;
        }

        switch (item.role) {
          case 0: item.roleName = 'Супер админ'; break;
          case 1: item.roleName = 'Админ'; break;
          case 2: item.roleName = 'Пользователь'; break;
        }

        item.isSelected = false;
      });

      console.log(this.loggingsUsers);

    }, error => {
      console.log(error);
    });
  }
}

class Key {
  keyId: number;
}


class LoggingKey {
  id: number;
  date: Data;
  internalNum: string;
  externalNum: string;
  description: string;
  status: number;
  statusName: string;
  author: string;

  groupId: number;
  groupName: string;
  groupAction: number;
  groupActionName: string;
  isSelected: boolean = false;
}

class LoggingDevice {
  id: number;
  date: Data;
  imei: string;
  ipspass: string;
  bootPass: string;
  name: string;
  description: string;
  rfaddr: string;
  status: number;
  statusName: string;

  author: string;

  groupName: string;
  groupAction: number;
  groupActionName: string;

  isSelected: boolean = false;

}

class LoggingUser {
  id: number;
  date: Data;
  login: string;
  name: string;
  status: number;
  statusName: string;
  role: number;
  roleName: string;
  author: string;

  isSelected: boolean = false;
}
