export class Key {
  id: number;
  status: number;
  internalNum: string; 
  externalNum: string;
  description: string;
  isActive: boolean = false;
  activeStatus: number = 0;
  isSelected: boolean = false;
  isSearched: boolean = true;
  group_id: number;
  authorId: number;
}
