import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { DlDateTimePickerDateModule } from 'angular-bootstrap-datetimepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderModule } from 'ngx-order-pipe';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { CardsComponent } from './cards/cards.component';
import { TasksComponent } from './tasks/tasks.component';
import { SettingsComponent } from './settings/settings.component';
import { SearchPipePipe } from './search-pipe.pipe';
import { UsersComponent } from './users/users.component';
import { AuthComponent } from './auth/auth.component';

import { AuthService } from './auth.service';
import { DeviceFilterPipe } from './device-filter.pipe';
import { KeyFilterPipe } from './key-filter.pipe';

import { Global } from './global';
import { UtcPipe } from './utc.pipe';
import { SortPipe } from './sort.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { CompaniesComponent } from './companies/companies.component';
import { LoggingComponent } from './logging/logging.component';
import { CompanySearchPipe } from './pipes/company-search.pipe';

import { LoggingGuard } from './guards/logging.guard'
import { UsersGuard } from './guards/users.guard'
import { TasksGuard } from './guards/tasks.guard'
import { CompaniesGuard } from './guards/companies.guard';
import { SearchCardsPipe } from './pipes/search-cards.pipe';
import { SearchDevicesPipe } from './pipes/search-devices.pipe';
import { SearchDevicesGroupPipe } from './pipes/search-devices-group.pipe';
import { SearchKeysGroupPipe } from './pipes/search-keys-group.pipe';
import { SearchTasksPipe } from './pipes/search-tasks.pipe';
import { SearchLoginsPipe } from './pipes/search-logins.pipe';
import { SearchLoggingsPipe } from './pipes/search-loggings.pipe';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    CardsComponent,
    TasksComponent,
    SettingsComponent,
    SearchPipePipe,
    UsersComponent,
    AuthComponent,
    DeviceFilterPipe,
    KeyFilterPipe,
    UtcPipe,
    SortPipe,
    UniquePipe,
    CompaniesComponent,
    CompanySearchPipe,
    SearchCardsPipe,
    SearchDevicesPipe,
    SearchDevicesGroupPipe,
    SearchKeysGroupPipe,
    SearchTasksPipe,
    SearchLoginsPipe,
    SearchLoggingsPipe,
    LoggingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    DlDateTimePickerDateModule,
    NgbModule,
    OrderModule,
    NgxDaterangepickerMd.forRoot(),
    RouterModule.forRoot([
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'cards', component: CardsComponent },
      { path: 'tasks', component: TasksComponent, canActivate: [TasksGuard] },
      { path: 'users', component: UsersComponent, canActivate: [UsersGuard] },
      { path: 'login', component: AuthComponent },
      { path: 'companies', component: CompaniesComponent, canActivate: [CompaniesGuard] },
      { path: 'logging', component: LoggingComponent, canActivate: [LoggingGuard] }
    ])
  ],
  providers: [AuthService, Global, UsersGuard, TasksGuard, CompaniesGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
