import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
  name: 'searchPipe',
  pure: false
})
export class SearchPipePipe implements PipeTransform {

  transform(items: any[], criteria: any): any {

    let props = ['internalNum', 'externalNum', 'description', 'imei', 'name', 'statusName', 'taskName', 'taskDescription', 'deviceImei', 'keyNumber', 'message', 'answer'];

    return items.filter(item => {
      if (!criteria) {
        return true;
      }
      for (let key in props) {        
        if (item[props[key]] && ("" + item[props[key]]).toLocaleLowerCase().includes(criteria.toLocaleLowerCase())) {
          item.isSearched = true
          console.log(item.isSearched);
          return true; 
        }else{
          item.isSearched = false
          console.log(item.isSearched);
        }
      } 
      return false;
    });
  }

}
